jQuery(document).ready(function ($) {
  $('.faq-block-list-elem-js').on('click', function() {
    if($(this).find('.faq-block-list-elem-content-js').hasClass('hidden')) {
      $(this).addClass('active');
      $(this).find('.faq-block-list-elem-content-js').slideDown(400).removeClass('hidden');
    } else {
      $(this).removeClass('active');
      $(this).find('.faq-block-list-elem-content-js').slideUp(400).addClass('hidden');
    }
  });
});